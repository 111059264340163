import classNames from 'classnames'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { HeaderPopMenu } from './header-pop-menu'
import { SvgMinus, SvgPlus } from './svg'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div`
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.4s;
  pointer-events: none;
  &.open {
    pointer-events: all;
    opacity: 1;
    overflow-x: scroll;
  }
`

const Inner = styled.div`
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
`

const Item = styled.a`
  display: block;
  position: relative;
`

const Label = styled.div`
  padding: 1rem;
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: 0.08em;
  font-family: var(--en-font);
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`

const MenuContainer = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.4s;
  &.open {
    grid-template-rows: 1fr;
  }
`

const MenuInner = styled.div`
  overflow: hidden;
`

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  svg {
    display: block;
    height: 1.125rem;
    width: 1.125rem;
    padding: 1rem;
  }
`

type MenuProps = {
  open: boolean
}
export const Menu: FunctionComponent<MenuProps> = ({open}) => {
  const {themeFileRootPath} = useSettings()
  const [openProduct, setOpenProduct] = useState(false)
  useEffect(() => {
    document.documentElement.style.overflowY = open ? 'clip' : ''
  }, [open])
  return <Outer className={classNames({open})}>
    <Inner>
      <Item href="/shop"><Label>TOP</Label></Item>
      <Item href="/shop/information_categories/topics"><Label>TOPICS</Label></Item>
      <Item as="div">
        <Label onClick={() => setOpenProduct(v => !v)}>PRODUCTS</Label>
        <MenuContainer className={classNames({open: openProduct})}>
          <MenuInner>
            <HeaderPopMenu />
          </MenuInner>
        </MenuContainer>
        <Icon>
          {openProduct ? <SvgMinus size={16}/> : <SvgPlus size={16}/>}
        </Icon>
      </Item>
      <Item href="https://d2w53g1q050m78.cloudfront.net/sknremedcom/uploads/pdf/how_to.pdf" target="_blank"><Label>HOW TO</Label></Item>
      <Item href="/shop/faq"><Label>FAQ</Label></Item>
      <Item href="/shop/information_categories/news"><Label>NEWS</Label></Item>
      <Item href="/shop/pages/point"><Label>MEMBERSHIP</Label></Item>
    </Inner>
  </Outer>
}